import { combineReducers } from 'redux';
import authReducer from './auth-reducers';
import userReducer from './user-reducers'
import studentReducers from './student-reducers'


const reducers = combineReducers({
    auth: authReducer, 
    user : userReducer,
    student:studentReducers
});

export default reducers;
