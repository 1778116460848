import { Types } from '../constants/auth-types';

const initialState = {
  
    loginData:{},
    googleData:{}
};
export default function authReducer(state = initialState, action) {
    switch (action.type) {
        
        case Types.LOGIN_SUCCESSFULLY:
            return { ...state, loginData: action.payload}
            case Types.GOOGLE_LOGIN_SUCCESSFULLY:
            return { ...state, loginData: action.payload}
        default:
            return state;
    }
}