import { useRoutes } from "react-router-dom";
import routes from './routers/routes'
import React from 'react'
import { useSelector } from "react-redux";
const SessionExpired = React.lazy(() => import("./container/SessionExpired"));

function App() {
  const status = useSelector((state) => state);
  React.useEffect(() => {
    document.body.style = 'background: #ECE9E9;';

  }, [status.auth.statuscode, status.student.statuscode, status.user.statuscode])

  return <>
    {useRoutes(routes())}

    <SessionExpired open={(status.auth.statuscode === 401 || status.student.statuscode === 401 ||
      status.user.statuscode === 401) && window.location.pathname !== '/'} />
  </>
}

export default App
